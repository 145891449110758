import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthenticationService } from '../shared/authentication.service';
import { ToastController } from '@ionic/angular'
//import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  tokenChangesSub;
  //currentMessage = new BehaviorSubject(null);
  constructor(private afMessaging: AngularFireMessaging,
    private afDb: AngularFireDatabase,
    private authService: AuthenticationService,
    private toastController: ToastController) { }

  requestPermission() { // requesting permission
    console.log('Requesting permission...');
    this.afMessaging.requestPermission
      .subscribe(
        () => {
          console.log('Notification permission granted.');
          this.getToken();
        },
        (error) => {
          console.log('Unable to get permission to notify.');
          console.error(error);
        }
      );
  }

  init() {
    async () => {
      const user = await this.authService.isUserReady();
      if (user) {
        // console.log('init messages');
        this.afMessaging.messaging.subscribe((_messaging) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });

        this.afMessaging.messages
          .subscribe(async (payload: any) => {
            let toast = await this.toastController.create({
              message: payload.notification.title + ': ' + payload.notification.body,
              duration: 15000,
              position: 'top'
            });
            toast.present();
            // this.currentMessage.next(payload);
          }, (error) => {
            console.log('onMessage error' + error);
          });

        if (this.isTokenSentToServer()) {
          this.getToken();
        }
      }
    };

  }

  getToken() {
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => { // USER-REQUESTED-TOKEN
          if (token) {
            this.sendTokenToServer(token);
            this.tokenChangesSub = this.afMessaging.tokenChanges
              .subscribe((refreshToken) => {
                this.sendTokenToServer(refreshToken, "Refreshing Token: ", true);
              },
                (error) => { console.log('tokenChanges error' + error); });

          } else {
            console.log('No Instance ID token available. Request permission to generate one.');
            this.requestPermission();
            this.setTokenSentToServer(false);
          }
        },
        (error) => {
          console.log('An error occurred while retrieving token. ', error);
          this.setTokenSentToServer(false);
        }
      );
  }

  removeToken() {
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => { // USER-REQUESTED-TOKEN
          let oldToken = token;
          this.afMessaging.deleteToken(token)
            .subscribe(() => {
              if (!!this.tokenChangesSub) {
                this.tokenChangesSub.unsubscribe();
              }
              this.afDb.database.ref('FCPO/fcmTokens').child(oldToken).set(null).then(() => {
                this.setTokenSentToServer(false);
                console.log('Token deleted.');
              });
            }, (error) => {
              console.log('Unable to delete token. ', error);
            });
        },
        (error) => {
          console.log('removeToken:An error occurred while retrieving token. ', error);
        }
      );
  }

  sendTokenToServer(token, msg: string = "", force = false) {
    if (!this.isTokenSentToServer() || force) {
      console.log(msg + ' Sending token to server...');

      if (!!this.authService.userData) {
        this.afDb.database.ref('FCPO/fcmTokens').child(token).set(this.authService.userData.uid).then(() => {
          this.setTokenSentToServer(true);
        });
      }
      else {
        console.log('sendTokenToService Error: UserData not found');
      }

    } else {
      console.log('Token already sent to server so won\'t send it again ' +
        'unless it changes');
    }
  }

  isTokenSentToServer() {
    return localStorage.getItem('fcmSentToServer') === '1';
  }

  setTokenSentToServer(sent: boolean) {
    localStorage.setItem('fcmSentToServer', sent ? '1' : '0');
  }

}
