// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB3C2_xnGJ0KfHWzqPjPW8BZDQW4GDf2OI",
    authDomain: "cl3ouat.firebaseapp.com",
    databaseURL: "https://cl3ouat.firebaseio.com",
    projectId: "cl3ouat",
    storageBucket: "cl3ouat.appspot.com",
    messagingSenderId: "293144899698",
    appId:"1:293144899698:web:b9402494ff27215c"
  },
  lrAccountId: 'LR1008',
  lrAccountId2: 'LR1009',
  title: 'PAPER TRADE',
  system: 'PAPER TRADE'
};

/**
 * Development
    apiKey: "AIzaSyC_16FHSGHOpUgAYykTKaiW0twLds98R3I",
    authDomain: "cl3o-fd4ea.firebaseapp.com",
    databaseURL: "https://cl3o-fd4ea.firebaseio.com",
    projectId: "cl3o-fd4ea",
    storageBucket: "cl3o-fd4ea.appspot.com",
    messagingSenderId: "388370233124",
    appId: "1:388370233124:web:032c8fa8de872138"

  * UAT
  apiKey: "AIzaSyB3C2_xnGJ0KfHWzqPjPW8BZDQW4GDf2OI",
    authDomain: "cl3ouat.firebaseapp.com",
    databaseURL: "https://cl3ouat.firebaseio.com",
    projectId: "cl3ouat",
    storageBucket: "cl3ouat.appspot.com",
    messagingSenderId: "293144899698",
    appId:"1:293144899698:web:b9402494ff27215c"
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
